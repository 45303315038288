var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _vm._l(_vm.coins, function (coin, idx) {
        return _c(
          "div",
          { key: coin + idx },
          [
            _c("mew-input", {
              staticClass: "mb-2",
              attrs: {
                id: idx,
                label: coin,
                placeholder: coin + " " + _vm.$t("common.addr"),
                "error-messages": _vm.errors[coin],
              },
              on: { input: _vm.validateCoin },
              model: {
                value: _vm.setCoins[coin],
                callback: function ($$v) {
                  _vm.$set(_vm.setCoins, coin, $$v)
                },
                expression: "setCoins[coin]",
              },
            }),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-center mt-3" },
        [
          _c("mew-button", {
            attrs: {
              title: _vm.$t("common.save"),
              "btn-size": "xlarge",
              disabled: !_vm.valid,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.submit.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }